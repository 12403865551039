<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";

export default {
  name: "AllTax",
  components: { DataTableComponent },
  data() {
    return {
      table: {
        modal: "Tax",
        path: "taxes",
        headers: [
          // {
          //   text: "Plan ID",
          //   value: "id"
          // },
          {
            text: this.$i18n.t("Name"),
            value: "en.name",
          },
          {
            text: this.$i18n.t("Arabic Name"),
            value: "ar.name",
          },
          {
            text: "%",
            value: "percentage",
          },
          {
            text: this.$i18n.t("Status"),
            value: "active",
          },
          {
            text: this.$i18n.t("Notes"),
            value: "notes",

            sortable: false,
          },

          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "3%",
          },
        ],
        title: this.$i18n.t("Taxes"),
        apiEndPoints: {
          list: "taxes",
          create: "taxes/store",
          edit: "taxes/update",
          delete: "taxes/destroy",
        },
        editedItem: {
          id: "",
          name: "",
          percentage: "",
          notes: "",
          active: "",
          countries: [],
          en: {
            name: "",
          },
          ar: {
            name: "",
          },
        },
        permissions: {
          list: "list-taxes",
          add: "add-taxes",
          edit: "edit-taxes",
          delete: "delete-taxes",
        },
      },
    };
  },
};
</script>
